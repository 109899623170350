<template>
    <section class="section" :style="{backgroundColor: bgTransparent ? 'transparent' : 'white'}" v-if="data!==null">
        <h1 v-if="title" class="section-title w-100">{{ title }}</h1>
        <div v-if="card === 'location'" class="row mobile-scrolling">
            <location-card v-for="(i, index) in data" :key="index" :item="i" :desktopAuto="desktopAuto" :disableLink="false" />
        </div>
        <div v-else-if="card === 'destination'" class="row mobile-scrolling">
            <destination-card v-for="(i, index) in data" :key="index" :item="i" :desktopAuto="desktopAuto" @cardClicked="cardClicked" />
        </div>
        <div v-else-if="card === 'event'" class="row mobile-scrolling">
            <event-card v-for="(i, index) in data" :key="index" :item="i" :desktopAuto="desktopAuto" :class="largeCards? 'card-large':''" />
        </div>
        <div v-else-if="card === 'category'" class="row mobile-scrolling">
            <category-card v-for="(i, index) in data" :key="index" :item="i" :desktopAuto="desktopAuto" />
        </div>
        <div v-else-if="card === 'reiseplan'" class="row mobile-scrolling">
            <reiseplan-card v-for="(i, index) in data" :key="index" :collection="i" :desktopAuto="desktopAuto" />
        </div>
    </section>
</template>
<script>
    export default {
        name: 'CardSlider',
        components: {
            LocationCard: () => import('./cards/LocationCard'),
            DestinationCard: () => import('./cards/DestinationCard'),
            EventCard: () => import('./cards/EventCard'),
            ReiseplanCard: () => import('./cards/ReiseplanCard'),
            CategoryCard: () => import('./cards/CategoryCard'),
        },
        props: {
            data: {
                type: Array,
            default: null,
                required: true
            },
            title: {
                type: String,
            default: ''
            },
            desktopAuto: {
                type: Boolean,
            default: false
            },
            bgTransparent: {
                type: Boolean,
            default: false
            },
            card: {
                type: String,
                required: true,
            default: 'basic',
                validator: card => ['event', 'category', 'reiseplan', 'destination', 'location'].indexOf(card) !== -1
            },
            icon: {
                type: String,
            default: 'map',
                validator: icon => ['map', 'edit'].indexOf(icon) !== -1
            },
            isDeleteable: {
                type: Boolean,
            default: false
            },
            largeCards: {
                type: Boolean,
            default: false
            }
        },
        methods: {
            cardClicked(value) {
                this.$emit('cardClicked', value);
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/scss/_variables.scss';
    @import '@/scss/_mixins.scss';

    .card {
        @include responsive($scroll-breakpoint) {
            &:first-child {
                margin-left: 15px;
            }

            &:last-child {
                margin-right: 15px;
            }
        }
    }

    .card-news {
      min-height: 282px !important;
  }

  .card-large {
    width: 400px !important;
  }

  .section-title {
    color: $text-color;
    border-bottom: 1px solid $text-color;
  }
</style>